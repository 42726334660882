import cn from 'classnames';
import { reduce } from 'lodash';
import { useTranslations } from 'next-intl';

import Carousel from 'components/betting/Carousel/Carousel';
import { SvgIconSize } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import { useAuthorization } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { AppLink } from 'types/applink';
import GameTmb from '../GameTmb/GameTmb';
import { TmbVariant } from '../types';
import { usePopularGamesCarousel } from './usePopularGamesCarousel';

export const PopularGamesCarousel = (): JSX.Element | null => {
    const { games } = usePopularGamesCarousel();
    const { isRegistrationFinished: isAuthorized } = useAuthorization();
    const t = useTranslations('HomePage.gincms.home_page');
    const isMobile = useResponsive().until('md');

    if (!games.length) {
        return null;
    }

    const [first, ...other] = games;

    const sliders = reduce(
        other,
        (acc: JSX.Element[], game, index, list) => {
            if (index % 2 !== 0) {
                return acc;
            }

            acc.push(
                <div className="flex w-full flex-col gap-2" key={game.id}>
                    <GameTmb
                        className="!mb-0"
                        isAuthorized={isAuthorized}
                        game={list[index]}
                        withTitle={false}
                    />
                    {list[index + 1] && (
                        <GameTmb
                            className="!mb-0"
                            isAuthorized={isAuthorized}
                            game={list[index + 1]}
                            withTitle={false}
                        />
                    )}
                </div>
            );

            return acc;
        },
        []
    );

    sliders.unshift(
        <div className="w-full" key={first.id}>
            <GameTmb
                className="!mb-0"
                isAuthorized={isAuthorized}
                variant={TmbVariant.FIRST_BIG_TMB}
                game={first}
                withTitle={false}
            />
        </div>
    );

    const viewAllButtonSlide = (
        <SmartLink
            href={AppLink.CASINO}
            className="flex w-full flex-col items-center justify-center bg-surface-middle px-2 text-center text-primary-white typo-sm-2"
        >
            {t('show_all')}
        </SmartLink>
    );

    return (
        <div
            className="flex flex-col gap-4 pl-2 md:pr-2"
            data-test="popular-games-carousel"
        >
            <div className="flex items-center justify-between">
                <SmartLink
                    href={AppLink.CASINO}
                    className="text-primary-white typo-md-2 hover:text-primary-orange-toxic-hover"
                    dataTest="popular-games-carousel__ttl"
                >
                    {t('popular_casino_games')}
                </SmartLink>
                <SmartLink
                    className={cn('text-grey-500 typo-base', {
                        'mr-2': isMobile,
                    })}
                    href={AppLink.CASINO}
                    dataTest="popular-games-carousel__show-all"
                >
                    {t('show_all')}
                </SmartLink>
            </div>
            <Carousel
                sliders={[...sliders, viewAllButtonSlide]}
                loop={false}
                withControls={!isMobile}
                spaceBetween={isMobile ? 8 : 12}
                slidesPerView="auto"
                slideClassName={cn(
                    '!h-[227px] !w-[109px] first:!w-[227px] last:!w-[76px] md:!h-[252px] md:!w-[122px] md:first:!mr-4 md:first:!w-[252px] last:md:!mr-[-9px]'
                )}
                controlIconClassName="fill-primary-white"
                controlClassName="!right-[-5px] !h-4"
                controlIconSize={SvgIconSize.WH_4}
            />
        </div>
    );
};
