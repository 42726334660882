import { useState } from 'react';
import { InViewHookResponse, useInView } from 'react-intersection-observer';

import { BatchKeys } from 'app-constants';
import { useGetGames } from 'gql/cms/queries/games/getGames.cms';
import { useAuthorization } from 'hooks';
import { Game } from 'types/gql.cms';

interface Input {
    themeSlug: string;
}

interface Output {
    games: Game[];
    count: number;
    isLoadedGames: boolean;
    isAuthorized: boolean;
    ref: InViewHookResponse['ref'];
}

const useGamesThemesCard = ({ themeSlug }: Input): Output => {
    const [isLoadedGames, setIsLoadedGames] = useState(false);
    const [ref, isInView] = useInView({
        triggerOnce: true,
        rootMargin: '100px',
    });
    const { isAuthorized } = useAuthorization();
    const { data } = useGetGames(() => ({
        variables: {
            limit: 32,
            filterByThemeSlug: themeSlug,
        },
        context: {
            batchKey: BatchKeys.GetCasinoGames,
        },
        skip: !isInView,
        onCompleted: () => {
            setIsLoadedGames(true);
        },
    }));

    const { games = [], count = 0 } = data?.games || {};

    return {
        games,
        count,
        isLoadedGames,
        isAuthorized,
        ref,
    };
};

export default useGamesThemesCard;
