import React, { FC, memo } from 'react';

import { WidgetNames } from 'app-constants';
import Widget, { makeGetServerSidePropsWidgetByName } from 'components/Widget';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { GetServerSidePropsWithMainApolloClient } from 'services/apollo';

import styles from './appMain.module.scss';

const AppMain: FC = () => {
    const { widgets } = useGsspData();

    return (
        <Widget
            widgetData={widgets?.[WidgetNames.MAIN_APP]?.widgetByName?.template}
            name={WidgetNames.MAIN_APP}
            className={styles['app-main']}
        />
    );
};

export const getAppMainSideProps: GetServerSidePropsWithMainApolloClient =
    makeGetServerSidePropsWidgetByName(WidgetNames.MAIN_APP);

export default memo(AppMain);
