import { FC, memo } from 'react';
import { fillLink } from 'cl-fill-link';
import { useTranslations } from 'next-intl';

import { AppLink } from 'app-constants';
import Image from 'components/Image';
import PackIcon, { IconsPack } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import { GetGameThemes } from 'types/gql.cms';
import useGamesThemesCard from '../useGamesThemesCard';

interface Props {
    theme: GetGameThemes['gameThemes'][number];
}

const GamesThemesCardMobile: FC<Props> = ({ theme }) => {
    const t = useTranslations('GamesThemeCard');
    const { imageId, slug, title } = theme;

    const { count, ref } = useGamesThemesCard({ themeSlug: slug });

    return (
        <SmartLink
            href={fillLink(AppLink.COLLECTION_COLLECTION_SLUG, {
                collectionSlug: slug,
            })}
            data-test="games-themes-card-mobile"
        >
            <div
                ref={ref}
                className="flex items-center rounded-medium bg-surface-middle px-3"
            >
                <div className="relative mr-4 h-[64px] w-[64px]">
                    {imageId && (
                        <div className="absolute inset-x-0 bottom-0 flex">
                            <Image
                                src={`/file/${imageId}`}
                                alt={title}
                                width={64}
                                height={82}
                            />
                        </div>
                    )}
                </div>

                <div className="flex flex-col">
                    <span className="text-base text-primary-white">
                        {title}
                    </span>
                    <span className="text-sm text-grey-500">
                        {`${t('gincms.themes.card.number_of_games')} ${count}`}
                    </span>
                </div>

                <PackIcon
                    id="branch-chevron-right"
                    pack={IconsPack.SpriteIcons}
                    className="ml-auto fill-primary-orange-toxic"
                />
            </div>
        </SmartLink>
    );
};

export default memo(GamesThemesCardMobile);
