import { useMemo } from 'react';
import { fillLink } from 'cl-fill-link';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { AppLink, BatchKeys, KeyValue } from 'app-constants';
import { useGetKeyValue } from 'gql/cms/queries/getKeyValue.cms';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { getTournamentSlugs } from '../../betting/Euro2024/utils';
import { CategoryType } from '../types';

type BlockData = {
    link: string;
    title: string;
    bgClassName?: string;
    fillClassName?: string;
};

interface Input {
    categoryType: CategoryType;
}

interface Output {
    isMobile: boolean;
    moreInfo: string;
    category: BlockData;
    promotion: BlockData;
}

const useCategoryLinkItem = ({ categoryType }: Input): Output => {
    const translation = useTranslations('HomePage.gincms.home_page');
    const translationEuro = useTranslations('betting-euro.euro');

    const { until } = useResponsive();
    const isMobile = until('md');

    const { data: keyValue } = useGetKeyValue(() => ({
        variables: {
            key: KeyValue.EURO_TOURNAMENT_SLUGS,
            default: '',
        },
        pagePropsKey: `keyValue.${KeyValue.EURO_TOURNAMENT_SLUGS}`,
        skip: categoryType !== CategoryType.Euro2024,
        context: {
            batchKey: BatchKeys.GetKeyValue,
        },
    }));

    const { bettingTournamentSlug } = getTournamentSlugs(
        keyValue?.keyValue.value
    );

    const { category, promotion } = useMemo(
        () =>
            getCategoryLetails({
                categoryType,
                euroTournamentSlug: bettingTournamentSlug,
                translation,
                translationEuro,
            }),
        [categoryType, translation, translationEuro, bettingTournamentSlug]
    );

    return {
        isMobile,
        moreInfo: translation('more'),
        category,
        promotion,
    };
};

function getCategoryLetails({
    categoryType,
    euroTournamentSlug,
    translation,
    translationEuro,
}: {
    categoryType: CategoryType;
    euroTournamentSlug: string;
    translation: ReturnType<
        typeof useTranslations<'HomePage.gincms.home_page'>
    >;
    translationEuro: ReturnType<typeof useTranslations<'betting-euro.euro'>>;
}): { category: BlockData; promotion: BlockData } {
    const bettingPromotion = {
        link: AppLink.BETTINGPROMOTIONS,
        title: translation('sports_promotions'),
        fillClassName: cn('fill-green-700'),
        bgClassName: cn('bg-green-700/10'),
    };

    switch (categoryType) {
        case CategoryType.Bets: {
            return {
                category: {
                    link: AppLink.BETS,
                    title: translation('bets'),
                    bgClassName: cn('bg-green-700'),
                },
                promotion: bettingPromotion,
            };
        }

        case CategoryType.Euro2024: {
            return {
                category: {
                    link: fillLink(AppLink.SPORTS_TOURNAMENT_SLUG, {
                        slug: euroTournamentSlug,
                    }),
                    title: translationEuro('euro2024'),
                    bgClassName: cn('bg-green-700'),
                },
                promotion: bettingPromotion,
            };
        }

        case CategoryType.Casino: {
            return {
                category: {
                    link: AppLink.CASINO,
                    title: translation('casino'),
                    bgClassName: cn('bg-crimson-500'),
                },
                promotion: {
                    link: AppLink.CASINOPROMOTIONS,
                    title: translation('casino_promotions'),
                    fillClassName: cn('fill-crimson-500'),
                    bgClassName: cn('bg-crimson-500/10'),
                },
            };
        }

        default: {
            return {
                category: { link: '', title: '' },
                promotion: { link: '', title: '' },
            };
        }
    }
}

export default useCategoryLinkItem;
