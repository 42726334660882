import { FC } from 'react';

import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { GetGameThemes } from 'types/gql.cms';
import GamesThemesCardDesktop from './GamesThemesCardDesktop';
import GamesThemesCardMobile from './GamesThemesCardMobile';

type GamesThemesCardProps = {
    theme: GetGameThemes['gameThemes'][number];
};

export const GamesThemesCard: FC<GamesThemesCardProps> = ({ theme }) => {
    const isMobile = useResponsive().until('md');

    return isMobile ? (
        <GamesThemesCardMobile key={theme.slug} theme={theme} />
    ) : (
        <GamesThemesCardDesktop key={theme.slug} theme={theme} />
    );
};
