import React, { FC, memo } from 'react';
import cn from 'classnames';

import BinaryStorageImage from 'components/BinaryStorageImage';

import LogoBG from 'assets/svg/components/logobg-gg.svg';

export enum TournamentLogoColor {
    Middle = 'bg-surface-middle',
    Light = 'bg-surface-light',
}

export enum TournamentLogoSize {
    Default = 'default',
    Small = 'small',
}

const sizeMap = {
    [TournamentLogoSize.Small]: 'h-12 w-12 p-3',
    [TournamentLogoSize.Default]: 'h-[108px] w-[108px] p-6',
};

const logoSizeMap = {
    [TournamentLogoSize.Small]: {
        logoSize: 24,
        defaultLogoSize: 'max-h-[24px] max-w-[24px]',
    },
    [TournamentLogoSize.Default]: {
        logoSize: 60,
        defaultLogoSize: 'max-h-[60px] max-w-[60px]',
    },
};

interface Props {
    logo: string;
    backgroundColor?: TournamentLogoColor;
    size?: TournamentLogoSize;
}

const TournamentLogo: FC<Props> = ({
    logo = '',
    backgroundColor = TournamentLogoColor.Middle,
    size = TournamentLogoSize.Default,
}: Props) => {
    const { logoSize, defaultLogoSize } = logoSizeMap[size];

    const sizeStyle = sizeMap[size];

    return (
        <div className="flex">
            <div
                className={cn(
                    'flex items-center justify-center rounded-full',
                    backgroundColor
                )}
            >
                <div
                    className={cn(
                        'flex items-center justify-center',
                        sizeStyle
                    )}
                    data-test="tournament-logo"
                >
                    {logo ? (
                        <BinaryStorageImage
                            src={logo}
                            width={logoSize}
                            height={logoSize}
                        />
                    ) : (
                        <LogoBG
                            className={cn('fill-grey-700', defaultLogoSize)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(TournamentLogo);
