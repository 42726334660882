import React, {
    DetailedHTMLProps,
    ForwardedRef,
    forwardRef,
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
    LegacyRef,
    useMemo,
} from 'react';
import { FieldError } from 'react-hook-form';
import cn from 'classnames';
import { reduce } from 'lodash';

import { getTransformedDataTestId } from 'utils';

import styles from './inputStyle.module.css';

interface Props
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    className?: string;
    title?: string;
    name?: string;
    id?: string;
    error?: FieldError;
    isDisabled?: boolean;
    isValid?: boolean;
    type?: HTMLInputTypeAttribute;
    helpText?: string;
    iconsLeft?: JSX.Element[];
    iconsRight?: JSX.Element[] | (JSX.Element | null)[];
    iconClasses?: string | { right?: string; left?: string };
    inputClasses?: string;
    dataTest?: string;
    refWrapper?: LegacyRef<HTMLDivElement>;
    inputMode?: 'text' | 'decimal';
}

const getIconsLength = (icons: object | undefined = {}): number => {
    return Object.keys(icons).length;
};

const setIconPaddingClasses = (
    leftIcons?: JSX.Element[],
    rightIcons?: JSX.Element[] | (JSX.Element | null)[]
): string => {
    const icons = [
        {
            count: getIconsLength(leftIcons),
            classes: ['pl-4', 'pl-12', 'pl-[80px]'],
        },
        {
            count: getIconsLength(rightIcons),
            classes: ['pr-4', 'pr-12', 'pr-[80px]'],
        },
    ];

    return reduce(
        icons,
        (acc, { classes, count }) => acc.concat(classes[count], ' '),
        ''
    );
};

const Input = (
    {
        error,
        className,
        helpText,
        iconsLeft,
        iconsRight,
        isDisabled,
        isValid = false,
        id,
        name,
        title,
        type = 'text',
        iconClasses,
        inputClasses,
        refWrapper,
        dataTest,
        inputMode = 'text',
        onKeyDown,
        ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
    const iconDefaultClasses = cn('absolute top-3 flex');

    const inputMainAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'base-input',
                propsAttribute: dataTest,
            }),
        [dataTest]
    );

    return (
        <div
            className={cn(className)}
            ref={refWrapper}
            data-test={inputMainAttr}
        >
            {title && (
                <div
                    className={cn('mb-1 typo-sm-1', {
                        ['text-grey-500']: !isDisabled,
                        ['text-grey-700']: !!isDisabled,
                    })}
                    data-test="base-input__title"
                >
                    {title}
                </div>
            )}
            <div className="relative">
                {Boolean(iconsLeft) && (
                    <div
                        className={cn(
                            typeof iconClasses === 'string'
                                ? iconClasses
                                : iconClasses?.left ||
                                      cn('left-4', iconDefaultClasses)
                        )}
                    >
                        {iconsLeft}
                    </div>
                )}
                <input
                    className={cn(
                        'h-12 w-full rounded-default bg-surface-input text-base outline outline-2 outline-offset-2 outline-transparent focus:text-primary-white',
                        styles.autofill,
                        setIconPaddingClasses(iconsLeft, iconsRight),
                        isValid && !isDisabled
                            ? 'text-primary-white'
                            : 'text-grey-500',
                        isDisabled
                            ? 'hover:text-grey-500'
                            : 'hover:text-primary-white',
                        {
                            ['border-default border-surface-input placeholder:text-grey-500 hover:border-grey-700  focus:border-grey-700']:
                                !error && !isDisabled,
                            ['border-default border-red-300']: !!error,
                            ['border-surface-light placeholder:text-grey-700']:
                                !!isDisabled,
                        },
                        inputClasses
                    )}
                    ref={ref}
                    disabled={isDisabled}
                    name={name}
                    id={id}
                    type={type}
                    data-test="base-input__input"
                    inputMode={inputMode}
                    onKeyDown={onKeyDown}
                    {...props}
                />
                {Boolean(iconsRight) && (
                    <div
                        className={cn(
                            typeof iconClasses === 'string'
                                ? iconClasses
                                : iconClasses?.right ||
                                      cn('right-4', iconDefaultClasses)
                        )}
                    >
                        {iconsRight}
                    </div>
                )}
            </div>

            {helpText && (
                <div
                    className={cn(
                        'mt-1 text-sm',
                        isDisabled ? 'text-grey-700' : 'text-grey-500'
                    )}
                    data-test="base-input__help-text"
                >
                    {helpText}
                </div>
            )}

            {error && (
                <div
                    className="mt-1 text-sm text-red-300"
                    data-test="base-input__error"
                >
                    {error.message}
                </div>
            )}
        </div>
    );
};

export default forwardRef(Input);
