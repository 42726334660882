import type { FC } from 'react';
import { memo } from 'react';

interface Props {
    count: number;
}

const CategorizerEventsCounter: FC<Props> = ({ count }) => {
    return (
        <div className="flex h-4 min-w-[16px] items-center justify-center rounded-[8px] bg-surface-light px-1 py-[2px] text-grey-500 typo-xs-2 ">
            {count}
        </div>
    );
};

export default memo(CategorizerEventsCounter);
