import React, { memo, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import cn from 'classnames';

import Image from 'components/Image';

interface Props {
    countryCode: string | undefined;
    className?: string;
    customHeight?: number;
}

const FlagIcon: React.FC<Props> = ({
    countryCode,
    className,
    customHeight,
}) => {
    const [Flag, setIcon] = useState<Function>();

    const isRenderPng =
        countryCode && /^(BITCOIN|WW|WW-|GB-)/g.test(countryCode);

    useEffect(() => {
        if (!countryCode || isRenderPng) return;

        import(
            /* webpackChunkName: "icon-country-flag" */ `country-flag-icons/react/3x2`
        )
            .then((svg) => {
                setIcon(() => svg[countryCode]);
            })
            .catch(captureException);
    }, [countryCode, isRenderPng]);

    if (isRenderPng) {
        return (
            <Image
                src={`/spa-assets/image/flags/${countryCode}.png`}
                width={16}
                height={Number(customHeight || 16)}
                className={className}
                unoptimized={true}
                alt=""
            />
        );
    }

    if (!Flag)
        return (
            <div
                className={cn(
                    'flex h-[11px] w-4 items-center justify-center bg-surface-light typo-sm-1',
                    className
                )}
            />
        );

    return (
        <Flag
            data-flag-country-code={countryCode}
            title={countryCode}
            className={cn('w-4', className)}
            alt="flag"
        />
    );
};

export default memo(FlagIcon);
