import React, { memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { AppLink } from 'app-constants';
import LoadMoreButton from 'components/casino/Games/GamesList/LoadMoreButton';
import SmartLink from 'components/SmartLink';
import Spinner from 'components/Spinner';
import withClientMessages from 'hocs/withClientMessages';
import { GamesThemesCard } from '../GamesThemesCard';
import GamesThemesWidgetStub from './GamesThemesWidgetStub';
import useGamesThemesWidget from './useGamesThemesWidget';

const GamesThemesWidget: React.FC = () => {
    const t = useTranslations('GamesThemes');

    const {
        slicedThemes,
        navigateToCollections,
        themesCount,
        isMobile,
        loading,
        isMainPage = false,
    } = useGamesThemesWidget();

    if (!themesCount) return null;

    if (loading) {
        return (
            <div
                className={cn('relative', isMobile ? 'h-[282px]' : 'h-[406px]')}
            >
                <Spinner className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
            </div>
        );
    }

    return (
        <div
            className={cn(
                isMainPage
                    ? 'w-full px-2'
                    : 'mb-[44px] mt-[30px] md:mb-[40px] md:mt-[17px]'
            )}
            data-test="games-themes-widget"
        >
            <div
                className={cn(
                    'flex justify-between',
                    isMainPage ? 'mb-6 md:mb-3' : 'mb-2 md:mb-3'
                )}
            >
                <SmartLink
                    href={AppLink.COLLECTIONS}
                    className={cn('text-primary-white typo-md-2')}
                    dataTest="games-themes-widget__ttl"
                >
                    {t(`gincms.themes.${isMainPage ? 'slots' : 'collections'}`)}
                </SmartLink>

                <SmartLink
                    href={AppLink.COLLECTIONS}
                    className={cn('text-grey-500 typo-base')}
                    dataTest="games-themes-widget__see-all"
                >
                    {t('gincms.themes.see_all')}

                    {!isMainPage && <span> ({themesCount}) </span>}
                </SmartLink>
            </div>
            <div
                className={cn(
                    'flex flex-col gap-y-6 md:grid',
                    isMainPage
                        ? 'gap-6 md:grid-cols-[repeat(auto-fill,minmax(427px,_1fr))]'
                        : 'gap-y-6 md:grid-cols-[repeat(auto-fill,minmax(376px,_1fr))] md:gap-x-4 md:gap-y-8'
                )}
            >
                {slicedThemes.map((theme) => {
                    return <GamesThemesCard key={theme.slug} theme={theme} />;
                })}
            </div>
            {!isMainPage && (
                <div className="mt-6 text-center md:mt-8">
                    <LoadMoreButton
                        isLoading={false}
                        onClick={navigateToCollections}
                    >
                        {t('gincms.themes.all_collections')}
                    </LoadMoreButton>
                </div>
            )}
        </div>
    );
};

export default memo(
    withClientMessages(
        GamesThemesWidget,
        ['GamesThemes', 'GamesThemeCard'],
        () => <GamesThemesWidgetStub />
    )
);
