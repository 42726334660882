import {
    FC,
    HTMLAttributes,
    memo,
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
} from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { Theme } from './constants';

interface Props
    extends PropsWithChildren,
        Pick<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
    isLoading?: boolean;
    theme?: Theme;
    isActiveIcon?: boolean;
    invertIcon?: boolean;
}

const LoadMoreButton: FC<Props> = ({
    isLoading,
    children,
    onClick,
    isActiveIcon,
    theme = Theme.Dark,
    invertIcon,
}) => {
    const t = useTranslations('General.gincms.btn');

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            if (isLoading) return;

            onClick?.(event);
        },
        [isLoading, onClick]
    );

    return (
        <button
            className="inline-block"
            onClick={handleClick}
            data-test="load-more-button"
        >
            <div
                className={cn(
                    'flex h-10 basis-[178px] items-center rounded-[8px] leading-4',
                    'px-[26px] text-base font-medium transition-all duration-300',
                    {
                        [cn(
                            'bg-surface-middle text-[#d8dff1] shadow-[0_10px_18px_rgba(26,37,75,.14)] hover:text-primary-white',
                            'hover:shadow-[inset_0_0_4px_#ffdb99] hover:drop-shadow-[0_8px_20px_rgba(255,219,153,.27)]'
                        )]: theme === Theme.Dark,
                    },
                    {
                        'bg-primary-white text-[#0a0b0d]':
                            theme === Theme.White,
                    }
                )}
                data-test="load-more-button__wrapper"
            >
                <PackIcon
                    className={cn('mr-[7px] max-h-[19px] max-w-[19px]', {
                        'fill-primary-orange-toxic': !isLoading,
                        'rotate-180': invertIcon,
                    })}
                    id={
                        isLoading || isActiveIcon
                            ? 'chevrons-down-active'
                            : 'chevrons-down'
                    }
                    pack={IconsPack.SpriteIcons}
                />

                <span
                    className="first-letter:uppercase"
                    data-test="load-more-button__text"
                >
                    {children || (invertIcon ? t('show_less') : t('show_more'))}
                </span>
            </div>
        </button>
    );
};

export default memo(LoadMoreButton);
