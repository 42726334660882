import { useCallback, useMemo } from 'react';

import { AppLink } from 'app-constants';
import { useRouter, useRouterPush } from 'components/HybridRouter';
import { useGetGameThemes } from 'gql/cms/queries/games/getGameThemes.cms';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { GetGameThemes } from 'types/gql.cms';

const MAX_VISIBLE_THEMES = 4;

interface Output {
    themesCount: number;
    isMobile: boolean;
    slicedThemes: GetGameThemes['gameThemes'];
    navigateToCollections: VoidFunction;
    loading: boolean;
    isMainPage: boolean;
}

const useGamesThemesWidget = (): Output => {
    const isMobile = useResponsive().until('md');
    const { pathname } = useRouter();

    const isMainPage = Boolean(pathname === AppLink.ROOT);

    const { push } = useRouterPush();
    const { data: { gameThemes = [] } = {}, loading } = useGetGameThemes(
        () => ({
            pagePropsKey: 'gameThemes',
        })
    );

    const slicedThemes = useMemo(
        () => gameThemes.slice(0, MAX_VISIBLE_THEMES),
        [gameThemes]
    );

    const navigateToCollections = useCallback(() => {
        push({ pathname: AppLink.COLLECTIONS });
    }, [push]);

    return {
        themesCount: gameThemes.length,
        slicedThemes,
        navigateToCollections,
        isMobile,
        loading,
        isMainPage,
    };
};

export default useGamesThemesWidget;
