import { useEffect, useMemo } from 'react';
import addDays from 'date-fns/addDays';
import formatRFC3339 from 'date-fns/formatRFC3339';
import { differenceBy, find, first } from 'lodash';

import {
    ACTIVE_MARKET_STATUSES,
    ACTIVE_SPORTEVENT_STATUSES,
    AppLink,
    LIVE_SPORTEVENT_STATUSES,
    POLLING_INTERVAL,
} from 'app-constants';
import { useGetSportEventListByFilters } from 'components/betting/MatchList/getSportEventListByFilters.bet';
import { useBanners } from 'components/betting/Promo/Banners';
import { useBannerZones } from 'components/betting/Promo/BannerZones';
import { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import { useRouter } from 'components/HybridRouter';
import { useDeepMemo, useRouterQuery } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { SportEventsInList } from 'types';
import { GetBannerZones, SportEventStatus } from 'types/gql.bet';
import { groupBannersByType } from 'utils';
import { useClientContext } from '../../../layouts/Root/ClientRenderContext';
import pinedSportEventIdsVar from './pinedSportEventIdsVar';

export const matchStatusesByPage: Record<string, Array<SportEventStatus>> = {
    [AppLink.LIVE]: LIVE_SPORTEVENT_STATUSES,
    [AppLink.NEXTTOGO]: [SportEventStatus.NotStarted],
    [AppLink.ROOT]: ACTIVE_SPORTEVENT_STATUSES,
    [AppLink.BETS]: ACTIVE_SPORTEVENT_STATUSES,
};

interface Input {
    excludeId?: string;
    sportEventMarketsLimit?: number;
}

interface Output {
    pinnedSportEvents: SportEventsInList;
    loading: boolean;
}

let isSettledData = false;

const usePinedSportEventList = ({
    excludeId,
    sportEventMarketsLimit,
}: Input): Output => {
    const { pathname } = useRouter();
    const { sportId = '' } = useRouterQuery();
    const { until } = useResponsive();
    const isMobile = until('md');
    const isClient = useClientContext();
    const { pinnedSportEvents: pinnedSportEventSsr } = useGsspData();

    const { data: bannerZoneData, loading } = useBannerZones({
        variables: {
            pageLocations: [
                sportId
                    ? BannerZoneLocation.PINNED_SPORT_EVENT_BY_SPORT
                    : BannerZoneLocation.PINNED_SPORT_EVENT,
            ],
        },
    });

    const dateTo = useMemo(() => getDateTo(), []);

    const marketLimit = sportEventMarketsLimit || isMobile ? 1 : 3;

    const zone = getCurrentZone(sportId, bannerZoneData?.bannerZones);
    const { banners = [] } = useBanners({
        zoneId: zone?.id,
        marketLimit,
        pollInterval: POLLING_INTERVAL,
        fetchPolicy: 'cache-and-network',
        onCompleted() {
            isSettledData = true;
        },
    });

    const maxCount =
        Number(
            zone?.settings?.find(({ name }) => name === 'maxCount')?.value
        ) || 5;

    const matchStatuses = matchStatusesByPage[pathname];

    const { sportEvents: bannerSportEvents, tournamentIds } =
        groupBannersByType(banners, matchStatuses);

    const tournamentMatchesLimit = maxCount - bannerSportEvents.length;

    const { data: tournamentPinnedSportEvent } = useGetSportEventListByFilters(
        () => ({
            variables: {
                tournamentIds,
                dateTo,
                marketStatuses: ACTIVE_MARKET_STATUSES,
                marketStatusesForSportEvent: ACTIVE_MARKET_STATUSES,
                matchStatuses,
                marketLimit,
                isTopMarkets: true,
                limit: tournamentMatchesLimit,
                offset: 0,
            },
            skip: !tournamentIds.length || !tournamentMatchesLimit,
            pollInterval: POLLING_INTERVAL,
        })
    );

    const filteredTournamentPinnedSportEvent = differenceBy(
        tournamentPinnedSportEvent?.matches.sportEvents,
        bannerSportEvents,
        'id'
    );

    const pinnedSportEvents = (
        filteredTournamentPinnedSportEvent?.length
            ? bannerSportEvents.concat(filteredTournamentPinnedSportEvent)
            : bannerSportEvents
    ).slice(0, maxCount);

    const memPinnedSportEventIds = useDeepMemo(
        pinnedSportEvents.map(({ id }) => id)
    );

    useEffect(() => {
        if (sportId && !zone) {
            pinedSportEventIdsVar([]);

            return;
        }

        pinedSportEventIdsVar(memPinnedSportEventIds);
    }, [memPinnedSportEventIds, sportId, zone]);

    const filteredPinnedSportEvents = useMemo(() => {
        if (!excludeId) return pinnedSportEvents;

        return pinnedSportEvents.filter(({ id }) => id !== excludeId);
    }, [excludeId, pinnedSportEvents]);

    const ssrData =
        (pathname === AppLink.ROOT || pathname === AppLink.BETS) &&
        !sportId &&
        pinnedSportEventSsr &&
        pinnedSportEventSsr?.matches?.sportEvents.length
            ? pinnedSportEventSsr.matches.sportEvents
            : [];

    return {
        pinnedSportEvents: isSettledData ? filteredPinnedSportEvents : ssrData,
        loading: loading || !isClient,
    };
};

function getDateTo(): string {
    const from = new Date();

    return formatRFC3339(addDays(from, 1));
}

function getCurrentZone(
    sportId: string,
    bannerZones: GetBannerZones['bannerZones'] | undefined
): GetBannerZones['bannerZones'][number] | undefined {
    if (!bannerZones?.length) return;

    if (!sportId) {
        return first(bannerZones);
    }

    return find(
        bannerZones,
        (z) =>
            find(z.settings, ({ name }) => name === 'sportId')?.value ===
            sportId
    );
}

export default usePinedSportEventList;
