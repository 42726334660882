import { useMemo } from 'react';

import { BatchKeys } from 'app-constants';
import { useGetGames } from 'gql/cms/queries/games/getGames.cms';
import { useAuthorization } from 'hooks';
import { useClientContext } from 'layouts/Root/ClientRenderContext';
import { Game } from 'types/gql.cms';

type Output = {
    games: Game[];
    count: number;
    isLoading: boolean;
};

export const usePopularGamesCarousel = (): Output => {
    const { isRegistrationFinished } = useAuthorization();
    const isClient = useClientContext();

    const variables = useMemo(
        () => ({
            isAuthorized: isRegistrationFinished,
            categorySlug: 'popular',
            limit: 19,
            offset: 0,
        }),
        [isRegistrationFinished]
    );

    const { loading, data } = useGetGames(() => ({
        variables,

        fetchPolicy: 'cache-and-network',
        returnPartialData: true,
        pagePropsKey: 'popularGames',
        context: {
            batchKey: BatchKeys.GetCasinoGames,
        },
    }));

    const { games, count } = data?.games || { games: [], count: 0 };

    return {
        games,
        count,
        isLoading: loading || !isClient,
    };
};
