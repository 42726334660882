import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import GameTmb from 'components/casino/Games/GameTmb';
import { TmbVariant } from 'components/casino/Games/types';
import Image from 'components/Image';
import { GetGameThemes } from 'types/gql.cms';
import useGamesThemesCard from '../useGamesThemesCard';
import GamesThemesCardAll from './GamesThemesCardAll';

interface Props {
    theme: GetGameThemes['gameThemes'][number];
}

const MAX_VISIBLE_GAMES = 4;

const GamesThemesCardDesktop: FC<Props> = ({ theme }) => {
    const t = useTranslations('GamesThemeCard');

    const { imageId, slug, title } = theme;

    const { games, count, ref, isLoadedGames, isAuthorized } =
        useGamesThemesCard({
            themeSlug: slug,
        });

    const slicedGames = useMemo(
        () => games.slice(0, MAX_VISIBLE_GAMES),
        [games]
    );

    return (
        <div
            ref={ref}
            className="relative flex h-[184px] flex-col rounded-medium bg-surface-middle px-4 pb-4 pt-6"
            data-test="games-themes-card-desktop"
        >
            <p
                className="mb-2 text-base text-primary-white"
                data-test="games-themes-card-desktop__title-text"
            >
                {t('gincms.themes.card.collection')}
            </p>
            <p
                className="text-lg-1 font-medium text-primary-white"
                data-test="games-themes-card-desktop__title"
            >
                {title}
            </p>
            <div className="mt-auto grid h-[64px] grid-cols-[repeat(5,64px)] gap-x-[6px]">
                {slicedGames.map((game) => (
                    <GameTmb
                        key={game.id}
                        game={game}
                        isAuthorized={isAuthorized}
                        variant={TmbVariant.COLLECTION_CARD}
                        withTitle={false}
                    />
                ))}
                <GamesThemesCardAll
                    className={cn({ 'opacity-0': !isLoadedGames })}
                    slug={slug}
                    count={count}
                />
            </div>
            {imageId && (
                <div className="absolute bottom-0 right-0 h-[204px] w-[160px]">
                    <Image src={`/file/${imageId}`} alt={title} fill={true} />
                </div>
            )}
        </div>
    );
};

export default memo(GamesThemesCardDesktop);
