import { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { AppLink } from 'app-constants';
import { SvgIconSize } from 'components/PackIcon';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { useClientContext } from 'layouts/Root/ClientRenderContext';
import SmartLink from '../../SmartLink';
import Carousel from '../Carousel/Carousel';
import { HeaderType } from '../SportEventRow';
import { OriginType } from '../SportEventRow/constants';
import SportEventRow from '../SportEventRow/SportEventRow';
import usePopularMatchesCarousel from './usePopularMatchesCarousel';

const PopularMatchesCarousel: FC = () => {
    const { sportEvents } = usePopularMatchesCarousel();
    const isMobile = useResponsive().until('md');
    const t = useTranslations('HomePage.gincms.home_page');
    const isClient = useClientContext();

    if (!sportEvents.length) return null;

    const sliders = sportEvents.map(
        ({ id, fixture, streams, markets, slug, meta, version }) => (
            <div className="w-full" key={id}>
                <SportEventRow
                    sportEventId={id}
                    fixture={fixture}
                    streams={streams}
                    markets={markets.slice(0, 1)}
                    slug={slug}
                    meta={meta}
                    version={version}
                    headerType={HeaderType.Tournament}
                    isResizable={false}
                    origin={OriginType.Carousel}
                />
            </div>
        )
    );

    return (
        <div
            className={cn('flex flex-col gap-4 pl-2 md:pr-2')}
            data-test="popular-matches-carousel"
        >
            <div className="flex items-center justify-between">
                <SmartLink
                    href={AppLink.BETS}
                    className="text-primary-white typo-md-2 hover:text-primary-orange-toxic-hover"
                    dataTest="popular-matches-carousel__ttl"
                >
                    {t('popular_matches')}
                </SmartLink>
                <SmartLink
                    className={cn('text-grey-500 typo-base', {
                        'mr-2': isMobile,
                    })}
                    href={AppLink.BETS}
                    dataTest="popular-matches-carousel__show-all"
                >
                    {t('show_all')}
                </SmartLink>
            </div>
            <Carousel
                sliders={sliders}
                loop={isClient}
                withControls={isClient && !isMobile}
                spaceBetween={4}
                slideClassName={cn('!w-[300px] xs:!w-[330px] md:!w-[400px]', {
                    'mr-1': !isClient,
                })}
                controlIconClassName="fill-primary-white"
                controlIconSize={SvgIconSize.WH_4}
            />
        </div>
    );
};

export default memo(PopularMatchesCarousel);
