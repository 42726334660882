import { Dispatch, SetStateAction } from 'react';

import { CompareStatus, StorageKey } from 'app-constants';
import { TournamentPlace, TournamentTeamRaceRound } from 'types/api.cms';
import { TournamentPlaceWithCompare } from './types';

interface Output {
    boTournamentId: string;
    bettingTournamentSlug: string;
}

export const getTournamentSlugs = (value?: string): Output => {
    const keyValueData = value || '';
    const keyValueSlugs = keyValueData.split(',');
    const [boTournamentId = '', bettingTournamentSlug = ''] = keyValueSlugs;

    return {
        boTournamentId,
        bettingTournamentSlug,
    };
};

export const getCurrentRoundFromRounds = <T extends TournamentTeamRaceRound>(
    rounds?: T[]
): T | undefined => {
    const now = +new Date();

    return rounds?.find(
        (round) =>
            +new Date(round.finishAt) > now && now > +new Date(round.startAt)
    );
};

const getTeamListMap = <T extends TournamentPlaceWithCompare | TournamentPlace>(
    teamList: T[]
) => {
    return teamList.reduce<Record<string, T>>((acc, team) => {
        if (!('teamId' in team)) return acc;

        const teamId = team?.teamId as string;

        acc[teamId] = team;

        return acc;
    }, {});
};

interface SetInitialTeamListInput {
    teamList: TournamentPlace[];
    setState: Dispatch<SetStateAction<TournamentPlaceWithCompare[]>>;
    delay?: number;
}

export const setInitialUserList = ({
    teamList,
    setState,
    delay = 5000,
}: SetInitialTeamListInput): void => {
    const getInitialTeamList = (
        prevTeamList: TournamentPlaceWithCompare[]
    ): TournamentPlaceWithCompare[] => {
        const teamListMap =
            getTeamListMap<TournamentPlaceWithCompare>(prevTeamList);

        return teamList.map((team) => ({
            ...team,
            prevPosition: teamListMap[team.teamId as string]?.position,
            compareStatus: CompareStatus.Equal,
        }));
    };

    setTimeout(() => {
        setState(getInitialTeamList);

        localStorage.setItem(
            StorageKey.EURO_TEAM_LIST,
            JSON.stringify(
                teamList.map((team) => ({
                    ...team,
                    compareStatus: CompareStatus.Equal,
                }))
            )
        );
    }, delay);
};

interface SetTeamListWithCompareStatusInput {
    teamList: TournamentPlace[];
    prevTeamList: TournamentPlaceWithCompare[];
}

export const setTeamListWithCompareStatus = ({
    teamList,
    prevTeamList,
}: SetTeamListWithCompareStatusInput): TournamentPlaceWithCompare[] => {
    const mapTeamList = getTeamListMap<TournamentPlace>(teamList);

    return prevTeamList.map((team) => {
        const newTeam = mapTeamList[team?.teamId as string];

        if (newTeam?.position < team?.position) {
            return {
                ...team,
                compareStatus: CompareStatus.Greater,
            };
        }

        if (newTeam?.position > team?.position) {
            return {
                ...team,
                compareStatus: CompareStatus.Smaller,
            };
        }

        return team;
    });
};
