import { ACTIVE_SPORTEVENT_STATUSES } from 'app-constants';
import { WithApolloClientsContext } from 'services/apollo';
import { logError } from 'services/logger';
import { GetSportEventListByFilters } from 'types/gql.bet';
import { groupBannersByType } from 'utils';
import { getTypeDevice } from '../../utilities/MobileDetect';
import { getServerPageGetSportEventListByFilters } from '../MatchList';
import { sportIdsBySportType } from '../MatchList/utils';
import { getServerPageGetBanners } from '../Promo/Banners/getBanners.bet';
import { Banner } from '../Promo/Banners/types';
import { carouselSlidesLimit } from './constants';
import { getPopularMatchesVariables } from './usePopularMatchesCarousel';

const getServerPinnedMatches = async (
    ctx: WithApolloClientsContext
): Promise<GetSportEventListByFilters | null> => {
    const { isMobile } = getTypeDevice(ctx);

    try {
        const data = await getServerPageGetBanners({
            variables: {
                zoneId: isMobile
                    ? 'mix-pinned_sport_event-mobile-0'
                    : 'mix-pinned_sport_event-desktop-0',
                marketLimit: 1,
                isClient: false,
            },
            fetchPolicy: 'no-cache',
        })(ctx);

        if (!data) return null;

        const { banners } = data;

        const { sportEvents } = groupBannersByType(
            banners as Banner[],
            ACTIVE_SPORTEVENT_STATUSES
        );

        return { matches: { count: sportEvents.length, sportEvents } };
    } catch (error) {
        logError({
            message: 'getServerPinnedMatchList',
            err: error,
            request: ctx.req,
        });
    }

    return null;
};

export interface GetServerPopularMatches {
    pinnedSportEvents: GetSportEventListByFilters | null;
    sportEvents?: GetSportEventListByFilters | null;
}

export const getServerPopularMatches = async (
    ctx: WithApolloClientsContext
): Promise<GetServerPopularMatches> => {
    const { isMobile } = getTypeDevice(ctx);
    const { resolvedUrl, sportIds = [] } = ctx;

    const pinnedSportEvents = await getServerPinnedMatches(ctx);

    const sportEventsLimit =
        carouselSlidesLimit - (pinnedSportEvents?.matches.count || 0);

    if (sportEventsLimit <= 0) {
        return { pinnedSportEvents };
    }

    const sportEvents = await getServerPageGetSportEventListByFilters({
        fetchPolicy: 'no-cache',
        variables: {
            isClient: false,
            ...getPopularMatchesVariables(sportEventsLimit)({
                query: ctx.query,
                isMobile,
                sportIds: sportIdsBySportType(resolvedUrl, sportIds),
                url: resolvedUrl,
            }),
        },
        context: {
            important: true,
        },
    })(ctx);

    return {
        pinnedSportEvents,
        sportEvents,
    };
};
