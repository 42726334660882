import { useMemo } from 'react';
import { concat, uniqBy } from 'lodash';

import {
    ACTIVE_MARKET_STATUSES,
    ACTIVE_SPORTEVENT_STATUSES,
    POLLING_INTERVAL,
} from 'app-constants';
import { useBettingLayout } from 'layouts/BettingLayout/BettingLayoutContext';
import { GetSportEventListByFilters, SportEventOrder } from 'types/gql.bet';
import { useRouter } from '../../HybridRouter';
import { useIsMobile } from '../../utilities/MobileDetect';
import {
    GetMatchListVariables,
    makeMatchListVariablesGetter,
} from '../MatchList';
import { useGetSportEventListByFilters } from '../MatchList/getSportEventListByFilters.bet';
import { sportIdsBySportType } from '../MatchList/utils';
import usePinedSportEventList from '../PinedSportEventList/usePinedSportEventList';
import { carouselSlidesLimit } from './constants';

interface Output {
    sportEvents: GetSportEventListByFilters['matches']['sportEvents'];
}

export const getPopularMatchesVariables = (
    limit: number
): GetMatchListVariables =>
    makeMatchListVariablesGetter(() => ({
        order: SportEventOrder.RankPersonalized,
        limit,
        marketLimit: 1,
        marketStatuses: ACTIVE_MARKET_STATUSES,
        matchStatuses: ACTIVE_SPORTEVENT_STATUSES,
    }));

const usePopularMatchesCarousel = (): Output => {
    const { isMobile } = useIsMobile();
    const { query, asPath } = useRouter();
    const { sportIds } = useBettingLayout();

    const { pinnedSportEvents } = usePinedSportEventList({
        sportEventMarketsLimit: 1,
    });

    const isFullCarouselWithPinnedEvents = pinnedSportEvents.length >= 5;

    const sportEventsLimit = isFullCarouselWithPinnedEvents
        ? 0
        : carouselSlidesLimit - pinnedSportEvents.length;

    const variables = useMemo(
        () =>
            getPopularMatchesVariables(sportEventsLimit)({
                isMobile,
                query,
                sportIds: sportIdsBySportType(asPath, sportIds),
                url: asPath,
            }),
        [isMobile, query, asPath, sportIds, sportEventsLimit]
    );

    const { data } = useGetSportEventListByFilters(() => ({
        variables,
        pollInterval: POLLING_INTERVAL,
        returnPartialData: true,
        pagePropsKey: 'sportEvents',
        fetchPolicy: 'cache-and-network',
        skip: isFullCarouselWithPinnedEvents,
    }));

    const listSportEvents = data?.matches.sportEvents || [];

    const sportEvents = uniqBy(
        concat(pinnedSportEvents, listSportEvents),
        (match) => match.id
    ).slice(0, carouselSlidesLimit);

    return {
        sportEvents,
    };
};

export default usePopularMatchesCarousel;
