import { hasCookie } from 'cookies-next';
import type { NextPageWithMessages } from 'next';

import { BatchKeys, CookiesType, KeyValue, LayoutType } from 'app-constants';
import AppMain, { getAppMainSideProps } from 'components/AppMain';
import BannerMainPage from 'components/BannerMainPage';
import BetslipPersistUpdating from 'components/betting/Betslip/BetslipPersistUpdating';
import MobileBetslipManager from 'components/betting/Betslip/MobileBetslipManager';
import { FeaturedTournamentZone } from 'components/betting/FeaturedTournaments';
import { getServerPageGetFeaturedTournaments } from 'components/betting/FeaturedTournaments/getFeaturedTournaments.bet';
import type { GetServerPopularMatches } from 'components/betting/PopularMatchesCarousel';
import PopularMatchesCarousel, {
    getServerPopularMatches,
} from 'components/betting/PopularMatchesCarousel';
import { useBannerZones } from 'components/betting/Promo/BannerZones';
import { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import SportTournaments from 'components/betting/SportTournaments';
import { PopularGamesCarousel } from 'components/casino/Games/PopularGamesCarousel';
import GamesThemesWidget from 'components/casino/GamesThemes/GamesThemesWidget';
import CategoriesLinks from 'components/CategoriesLinks';
import { getTypeDevice } from 'components/utilities/MobileDetect';
import { getServerPageGetGames } from 'gql/cms/queries/games/getGames.cms';
import { getServerPageGetGameThemes } from 'gql/cms/queries/games/getGameThemes.cms';
import { getServerPageGetKeyValue } from 'gql/cms/queries/getKeyValue.cms';
import SidebarPopup from 'layouts/BettingLayout/Sidebar/SidebarPopup';
import { makeWithMainLayoutServerProps } from 'layouts/MainLayout';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { WithApolloClientsContext, withBettingClient } from 'services/apollo';
import { Feature, useFeatureFlags } from 'services/features';
import { GetServerSidePageProps, GetServerSidePagePropsData } from 'types';
import { GetFeaturedTournaments } from 'types/gql.bet';
import { GetGames, GetGameThemes } from 'types/gql.cms';
import { getInitialServerSideProps, getMessages } from 'utils';
import getSettledResult from 'utils/getSettledResult';

type TupleSettledResult = [
    GetServerPopularMatches,
    GetFeaturedTournaments,
    GetGames,
    { [p: string]: {} },
    { props: GetServerSidePagePropsData },
    GetGameThemes,
];

const Home: NextPageWithMessages = () => {
    const { features } = useFeatureFlags();
    const { is } = useResponsive();
    const isMobileBetslip = is('xs');

    const isHomePopularMatches = features.isEnabled(Feature.HomePopularMatches);
    const isHomePopularCasinoGames = features.isEnabled(
        Feature.HomePopularCasinoGames
    );
    const isEnabledMainBanner = features.isEnabled(Feature.HomeMainBanner);
    const isHomeCategoriesLinks = features.isEnabled(
        Feature.HomeCategoriesLinks
    );
    const isEnabledMainSlots = features.isEnabled(Feature.HomeMainSlot);
    const isHomeSportTournaments = features.isEnabled(
        Feature.HomeSportTournaments
    );
    const isEnabledMainApp = features.isEnabled(Feature.HomeMainApplication);

    const { loading } = useBannerZones({
        fetchPolicy: 'cache-first',
        variables: {
            pageLocations: [
                BannerZoneLocation.BOTTOM,
                BannerZoneLocation.PINNED_SPORT_EVENT,
            ],
        },
    });

    return (
        <>
            <div className="mt-2 hidden border-t-default border-surface-middle md:block" />
            <div className="mx-auto flex max-w-[1296px] flex-col gap-4 md:mt-8">
                {isHomeCategoriesLinks && <CategoriesLinks />}
                {isHomePopularCasinoGames && <PopularGamesCarousel />}
                {isHomePopularMatches && <PopularMatchesCarousel />}
                {isEnabledMainBanner && (
                    <BannerMainPage
                        bannerZoneLocation={BannerZoneLocation.BOTTOM}
                        isLoadingBannerZones={loading}
                    />
                )}
                {isEnabledMainSlots && <GamesThemesWidget />}
                {isHomeSportTournaments && <SportTournaments />}
                {isEnabledMainApp && <AppMain />}
                {/* Recent won */}
                {/* Exclusive tournament */}
                <BetslipPersistUpdating />
                {isMobileBetslip ? <MobileBetslipManager /> : <SidebarPopup />}
            </div>
        </>
    );
};

const getHomeServerSidePageProps = async (
    ctx: WithApolloClientsContext
): Promise<GetServerSidePageProps> => {
    const isAuthorized = hasCookie(CookiesType.Paseto, {
        req: ctx.req,
    });
    const { env } = getInitialServerSideProps(ctx);
    const isSsrEnabledBettings = process.env.BETTING_SSR_ENABLE === 'true';

    const isEuro2024 = await ctx?.featureFlagManager?.isEnabledSsr(
        Feature.Euro2024
    );

    const { isMobile } = getTypeDevice(ctx);
    const popularGamesLimit = isMobile ? 7 : 15;

    const [
        popularMatches,
        featuredTournaments,
        popularGames,
        messages,
        appWidget,
        gameThemes,
    ] = await getSettledResult<TupleSettledResult>({
        promises: [
            isSsrEnabledBettings ? getServerPopularMatches(ctx) : null,
            isSsrEnabledBettings
                ? getServerPageGetFeaturedTournaments({
                      variables: {
                          zoneId: FeaturedTournamentZone.MixLeftWeb,
                          isClient: false,
                      },
                  })(ctx)
                : null,
            getServerPageGetGames({
                variables: {
                    isAuthorized,
                    categorySlug: 'popular',
                    limit: popularGamesLimit,
                    offset: 0,
                    isClient: false,
                },
                fetchPolicy: 'no-cache',
                context: {
                    batchKey: BatchKeys.GetCasinoGames,
                },
            })(ctx),
            getMessages(ctx, Home.settings.messages),
            getAppMainSideProps(ctx),
            getServerPageGetGameThemes()(ctx),
        ],
        label: 'getHomeServerSidePageProps',
        errorMessage: 'getHomeServerSidePageProps request failed',
        request: ctx.req,
    });

    const gsspData = {
        ...popularMatches,
        featuredTournaments,
        popularGames,
        widgets: appWidget?.props?.gsspData?.widgets,
        gameThemes,
    };

    if (!isEuro2024) {
        return {
            props: {
                gsspData,
                messages,
                env,
            },
        };
    }

    const keyValue = await getServerPageGetKeyValue({
        variables: {
            key: KeyValue.EURO_TOURNAMENT_SLUGS,
            default: '',
        },
        context: {
            batchKey: BatchKeys.GetKeyValue,
        },
    })(ctx);

    return {
        props: {
            gsspData: {
                ...gsspData,
                keyValue: {
                    [KeyValue.EURO_TOURNAMENT_SLUGS]: keyValue,
                },
            },
            messages,
            env,
        },
    };
};

export const getServerSideProps = makeWithMainLayoutServerProps({
    gsspHandler: async (ctx) => {
        return withBettingClient(getHomeServerSidePageProps)(
            ctx as WithApolloClientsContext
        );
    },
});

Home.settings = {
    layoutType: LayoutType.BettingStatic,
    messages: [
        'HomePage',
        'GamesThemeCard',
        'GamesThemes',
        'betting-specifiers',
        'betting-global',
        'betting-countries',
        'betting-euro',
    ],
};
export default Home;
