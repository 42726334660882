import React, { FC } from 'react';
import cn from 'classnames';

import Spinner from 'components/Spinner';
import useGamesThemesWidget from './useGamesThemesWidget';

const GamesThemesWidgetStub: FC = () => {
    const { isMobile } = useGamesThemesWidget();

    return (
        <div className={cn('relative', isMobile ? 'h-[282px]' : 'h-[406px]')}>
            <Spinner className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
    );
};

export default GamesThemesWidgetStub;
