import { FC, HTMLAttributes, memo } from 'react';
import { fillLink } from 'cl-fill-link';
import cn from 'classnames';

import { AppLink } from 'app-constants';
import PackIcon, { IconsPack } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';

interface Props extends Pick<HTMLAttributes<HTMLElement>, 'className'> {
    count: number;
    slug: string;
}

const GamesThemesCardAll: FC<Props> = ({ slug, count, className }) => (
    <SmartLink
        href={fillLink(AppLink.COLLECTION_COLLECTION_SLUG, {
            collectionSlug: slug,
        })}
        className={cn(
            'group relative z-1 flex cursor-pointer flex-col items-center justify-center',
            className
        )}
        dataTest="games-themes-card-all"
    >
        <div
            className={cn(
                'flex h-full w-full flex-col items-center justify-center rounded-[8px] bg-[rgba(81,81,81,.8)]',
                'shadow-[rgba(20,20,20,.4)_0px_4px_4px] transition-all group-hover:scale-105'
            )}
        >
            <span className="text-base font-bold leading-6 text-primary-white">
                {count}
            </span>
            <PackIcon
                id="branch-chevron-right"
                pack={IconsPack.SpriteIcons}
                className="z-2 fill-primary-white"
            />
        </div>
    </SmartLink>
);

export default memo(GamesThemesCardAll);
