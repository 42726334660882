import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon/PackIcon';
import SmartLink from 'components/SmartLink';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { FeaturedConfig } from '../../FeaturedTournaments/types';
import TournamentLogo, {
    TournamentLogoColor,
    TournamentLogoSize,
} from '../../Tournament/TournamentInfo/TournamentLogo';

interface Props {
    tournament: FeaturedConfig;
}

const SportTournament: FC<Props> = ({ tournament }) => {
    const t = useTranslations('HomePage.gincms.home_page');
    const isTablet = useResponsive().until('md');

    const { name, logo, linkToTournament, sportEventCount } = tournament;

    const count = sportEventCount
        ? `${t('number_of_matches')} ${sportEventCount}`
        : '';

    return (
        <SmartLink
            href={linkToTournament}
            className="flex w-full grow items-center bg-surface-middle px-4 py-2 md:flex-col md:py-4"
            dataTest="sport-tournament"
        >
            <TournamentLogo
                logo={logo}
                backgroundColor={TournamentLogoColor.Light}
                size={isTablet ? TournamentLogoSize.Small : undefined}
            />
            <div className="ml-4 flex flex-col truncate md:ml-0 md:mt-4 md:justify-center">
                <div
                    className={cn(
                        'truncate text-primary-white typo-md-2 md:mb-2 md:text-center',
                        { 'pr-2': isTablet }
                    )}
                    data-test="sport-tournament__name"
                >
                    {name}
                </div>
                <div
                    className={cn(
                        'text-grey-500 typo-sm-1 md:text-center md:!text-primary-white',
                        {
                            'h-4': !count,
                        }
                    )}
                    data-test="sport-tournament__count"
                >
                    {count}
                </div>
            </div>
            {isTablet && (
                <PackIcon
                    id="branch-chevron-right"
                    pack={IconsPack.SpriteIcons}
                    className="ml-auto fill-primary-orange-toxic"
                />
            )}
        </SmartLink>
    );
};

export default SportTournament;
