import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { OverlayType } from 'app-constants';
import SmartLink from 'components/SmartLink';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { AppLink } from 'types/applink';
import { FeaturedTournamentZone } from '../FeaturedTournaments';
import useFeatureTournaments from '../FeaturedTournaments/useFeatureTournaments';
import SportTournament from './SportTournament';

const SportTournaments: FC = () => {
    const { transformedZoneConfigs } = useFeatureTournaments(
        FeaturedTournamentZone.MixLeftWeb
    );
    const t = useTranslations('HomePage.gincms.home_page');
    const isTablet = useResponsive().until('md');

    const tournaments = transformedZoneConfigs.slice(0, 3);

    if (!tournaments.length) return null;

    const sportTournamentsCn = 'text-primary-white typo-md-2';

    return (
        <div
            className={cn('flex flex-col gap-4 px-2')}
            data-test="sport-tournaments"
        >
            <div className="flex items-center justify-between">
                <div
                    className={sportTournamentsCn}
                    data-test="sport-tournaments__ttl"
                >
                    {t('sports_tournaments')}
                </div>
                {isTablet && (
                    <SmartLink
                        className={cn('text-grey-500 typo-base')}
                        href={`${AppLink.BETS}?overlayType=${OverlayType.FEATURED_TOURNAMENTS_LIST}`}
                    >
                        {t('show_all')}
                    </SmartLink>
                )}
            </div>
            <div
                className="flex flex-col gap-4 md:flex-row md:!gap-2"
                data-test="sport-tournaments__list"
            >
                {tournaments.map((tournament) => {
                    return (
                        <SportTournament
                            key={tournament.slug}
                            tournament={tournament}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SportTournaments;
