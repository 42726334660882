import { FC, memo } from 'react';

import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { Feature, useFeatureFlags } from 'services/features';
import CategoryLinkItem from './CategoryLinkItem';
import { CategoryType } from './types';

const CategoriesLinks: FC = () => {
    const { until } = useResponsive();
    const isMobile = until('md');

    const { features } = useFeatureFlags();
    const isEuro2024 = features.isEnabled(Feature.Euro2024);

    return (
        <div className="flex gap-1 px-2" data-test="category-link">
            <CategoryLinkItem
                categoryType={CategoryType.Casino}
                image={`/spa-assets/image/home-page/casino-category${
                    isMobile ? '-mobile' : ''
                }.png`}
            />
            {isEuro2024 ? (
                <CategoryLinkItem
                    categoryType={CategoryType.Euro2024}
                    image="/spa-assets/image/euro2024/albert/win-pose-right-cup.png"
                />
            ) : (
                <CategoryLinkItem
                    categoryType={CategoryType.Bets}
                    image={`/spa-assets/image/home-page/bet-category${
                        isMobile ? '-mobile' : ''
                    }.png`}
                />
            )}
        </div>
    );
};

export default memo(CategoriesLinks);
