import React, { FC, memo } from 'react';

import type { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import { withClientRender } from 'hocs';
import { AnalyticAttrs, Category } from 'services/GoogleAnalytics';
import TopBannersCarousel from '../betting/Promo/TopBannersCarousel';
import { TopPromoTournamentBannerStub } from '../betting/Promo/TopPromoTournamentBanner';

interface Props {
    bannerZoneLocation: BannerZoneLocation;
    isLoadingBannerZones: boolean;
}

const ANALYTIC: AnalyticAttrs = {
    'data-category': Category.HomeMainBanner,
};
const BannerMainPage: FC<Props> = ({
    bannerZoneLocation,
    isLoadingBannerZones,
}) => {
    if (isLoadingBannerZones) {
        return <TopPromoTournamentBannerStub />;
    }

    return (
        <TopBannersCarousel
            bannerZoneLocation={bannerZoneLocation}
            analyticData={ANALYTIC}
            paddingBottomStyle={false}
        />
    );
};

export default withClientRender(memo(BannerMainPage), () => (
    <TopPromoTournamentBannerStub />
));
