import { FC, memo } from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import { SvgIconSize } from 'components/SvgIcon';
import { CategoryType } from '../types';
import useCategoryLinkItem from './useCategoryLinkItem';

const gradientClassName = cn(
    'after:absolute after:inset-x-0 after:bottom-0 after:h-8 after:w-full after:bg-gradient-to-t after:from-surface-middle after:to-transparent'
);

interface Props {
    image: string;
    categoryType: CategoryType;
}

const CategoryLinkItem: FC<Props> = ({ categoryType, image }) => {
    const { moreInfo, category, promotion, isMobile } = useCategoryLinkItem({
        categoryType,
    });

    const isEuroCategory = categoryType === CategoryType.Euro2024;

    const arrowIcon = (
        <PackIcon
            id="branch-chevron-right"
            pack={IconsPack.SpriteIcons}
            className="fill-primary-white"
            size={SvgIconSize.WH_3}
            dataTest="category-link__arrow"
        />
    );

    return (
        <div
            className="flex w-[50%] shrink-0 flex-col gap-1 text-primary-white"
            data-test="category-link__item"
        >
            <SmartLink
                className={cn(
                    'relative flex h-[72px] bg-surface-middle bg-right bg-no-repeat pl-2 md:h-[95px] md:bg-[center_right_2rem] md:pl-4',
                    { [gradientClassName]: isMobile }
                )}
                href={category.link}
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize:
                        isEuroCategory && isMobile ? 'contain' : 'auto',
                }}
                dataTest="category-link__gaming"
            >
                <div className="mt-2 flex max-w-max basis-10/12 flex-col overflow-hidden md:mt-4">
                    <div
                        className={cn(
                            'mb-4 flex px-2 md:mb-2',
                            category.bgClassName
                        )}
                        data-test="category-link__gaming-title"
                    >
                        <span className="mb-[2px] truncate py-1 font-klapt text-sm uppercase leading-none md:mb-[5px] md:py-0 md:typo-md-1">
                            {category.title}
                        </span>
                    </div>
                    <div
                        className="flex items-center"
                        data-test="category-link__gaming-more-info"
                    >
                        <span className="mr-2 typo-xs-1 md:text-sm">
                            {moreInfo}
                        </span>
                        {!isMobile && arrowIcon}
                    </div>
                </div>
                {isMobile && (
                    <div className="absolute bottom-3 right-3">{arrowIcon}</div>
                )}
            </SmartLink>
            <SmartLink
                className="flex grow items-center justify-between border-y-default border-solid border-surface-middle bg-surface-middle py-2 pl-2 pr-3 md:pl-4"
                href={promotion.link}
                dataTest="category-link__promo"
            >
                <div className="flex items-center">
                    <div
                        className={cn(
                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-full',
                            promotion.bgClassName
                        )}
                    >
                        <PackIcon
                            id="promo-sub-bonus"
                            pack={IconsPack.SpriteIcons}
                            className={promotion.fillClassName}
                            size={SvgIconSize.WH_4}
                        />
                    </div>
                    <span
                        className="mb-[2px] ml-2 mr-1 line-clamp-2 font-klapt uppercase typo-sm-1"
                        style={{ wordBreak: 'break-word' }}
                        data-test="category-link__promo-title"
                    >
                        {promotion.title}
                    </span>
                </div>
                {arrowIcon}
            </SmartLink>
        </div>
    );
};

export default memo(CategoryLinkItem);
